/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'settings': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 3h10a4 4 0 010 8H7a4 4 0 110-8zm0 6a2 2 0 100-4 2 2 0 000 4z" _fill="#fff"/><path pid="1" opacity=".3" fill-rule="evenodd" clip-rule="evenodd" d="M7 13h10a4 4 0 010 8H7a4 4 0 010-8zm10 6a2 2 0 100-4 2 2 0 000 4z" _fill="#fff"/>'
  }
})
